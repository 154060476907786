import React, { useState } from "react";
import { Link, FormattedMessage } from "gatsby-plugin-react-intl";
import { StaticImage } from "gatsby-plugin-image";

import LanguageSwitcher from "./languageSwitcher";

import LocalizedLink from "../helpers/localizedLink";

const Nav = (props) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <nav className="absolute top-0 z-40 w-screen ">
      <div className="w-full xl:w-1220 mx-auto px-4">
        <div className="relative flex items-center justify-between h-20">
          {/* <!-- Logo -->*/}
          <Link to="/" className="flex-shrink-0 flex items-center">
            {!props.isLight && (
              <StaticImage
                src="../../images/reel/reel_logo_black.png"
                alt="reel logo"
                placeholder="blurred"
                layout="constrained"
                loading="eager"
                height={14}
              />
            )}
            {props.isLight && (
              <StaticImage
                src={"../../images/reel/reel_logo_white.png"}
                alt="reel logo"
                placeholder="blurred"
                layout="constrained"
                loading="eager"
                height={14}
              />
            )}
          </Link>

          <div className="flex items-center lg:hidden">
            {/* <!-- Mobile menu button-->*/}
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded={showMenu}
              onClick={() => setShowMenu(!showMenu)}
            >
              <span className="sr-only">Open main menu</span>
              {/* <!--
                Icon when menu is closed.
              -->*/}
              {!showMenu && (
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke={props.isLight ? "#fff" : "#000"}
                  aria-hidden={showMenu}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}

              {/* <!--
                Icon when menu is open.
              -->*/}
              {showMenu && (
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke={props.isLight ? "#fff" : "#000"}
                  aria-hidden={!showMenu}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>

          <div
            className=" hidden lg:block lg:pl-24"
            style={{
              color: props.isLight ? "#fff" : "#000",
            }}
          >
            <div className="flex flex-row space-x-8 text-center">
              <Link className="font-regular" to="/solution" aria-current="page">
                <div className="px-3 py-2 text-base font-regular cursor-pointer">
                  <FormattedMessage id="nav_solution" />
                </div>
              </Link>

              <Link className="font-regular" to="/sustainability">
                <div className="px-3 py-2 text-base font-regular cursor-pointer">
                  <FormattedMessage id="nav_sustainability" />
                </div>
              </Link>

              <Link className="font-regular" to="/about">
                <div className="px-3 py-2 text-base font-regular cursor-pointer">
                  <FormattedMessage id="nav_about" />
                </div>
              </Link>

              <Link
                className="px-3 py-2 text-base font-semibold cursor-pointer tracking-wide"
                to="/offer"
                style={{}}
              >
                <button
                  type="button"
                  className="btn-purple-solid font-regular h-9 px-0 py-0 w-28 text-base -mt-1"
                  style={{
                    backgroundColor: props.isLight ? "#fff" : "#6A00FF",
                    color: props.isLight ? "#000" : "#fff",
                  }}
                >
                  <FormattedMessage id="get_offer" />
                </button>
              </Link>
            </div>
          </div>

          <div className="hidden lg:flex space-x-4"
            style={{
              color: props.isLight ? "#fff" : "#000",
            }}>
            <LanguageSwitcher isLight={props.isLight}></LanguageSwitcher>
            <LocalizedLink className="font-regular" to="/contact">
              <div className="px-3 py-1 text-base font-regular cursor-pointer">
                <FormattedMessage id="nav_contact" />
              </div>
            </LocalizedLink>
          </div>
        </div>
      </div>

      {/* <!-- Mobile menu, show/hide based on menu state. -->*/}
      {
        showMenu && (
          <div className="lg:hidden" id="mobile-menu">
            <div className="px-2 pt-2 pb-3 space-y-1 bg-white text-gray-700 border-b border-gray-300">
              <Link to="/solution" aria-current="page">
                <div className="block px-3 py-2 text-base font-regular">
                  <FormattedMessage id="nav_solution" />
                </div>
              </Link>

              <Link to="/sustainability">
                <div className="block px-3 py-2 text-base font-regular">
                  <FormattedMessage id="nav_sustainability" />
                </div>
              </Link>

              <Link to="/about">
                <div className="block px-3 py-2 text-base font-regular">
                  <FormattedMessage id="nav_about" />
                </div>
              </Link>

              <Link
                className="px-3 py-2 text-base font-regular cursor-pointer"
                to="/offer"
              >
                <FormattedMessage id="get_offer" />
              </Link>

              <LocalizedLink to="/contact">
                <div className="block px-3 py-2 text-base font-regular">
                  <FormattedMessage id="nav_contact" />
                </div>
              </LocalizedLink>

              <LanguageSwitcher></LanguageSwitcher>
            </div>
          </div>
        )
      }
    </nav >
  );
};

export default Nav;
