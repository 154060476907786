import React from "react";

const PrivacyPolicyLink = () => {
  return (
    <a href="/privacy-policy" title="Privacy policy">
      Privacy Policy
    </a>
  );
};

export default PrivacyPolicyLink;
