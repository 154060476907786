import React from "react";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import LocaleData from "../helpers/localeData";

const LocalizedLink = ({ children, to }) => {
  const intl = useIntl();

  const slug = to !== undefined ? to : "";

  return (
    <Link to={slug + "-" + LocaleData(intl.locale)["short"].toLowerCase()}>
      {children}
    </Link>
  );
};

export default LocalizedLink;
