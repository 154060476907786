import React from "react";

const CookiePolicyLink = () => {
  return (
    <a href="https://www.iubenda.com/privacy-policy/72778846/cookie-policy">
      Cookie Policy
    </a>
  );
};

export default CookiePolicyLink;
