import React, { useState } from "react";

import ReactModal from 'react-modal';
import { FormattedMessage } from "gatsby-plugin-react-intl";
import { Link } from "gatsby-plugin-react-intl";
import { StaticImage } from "gatsby-plugin-image";

import PrivacyPolicyLink from "../helpers/privacyPolicyLink";
import CookiePolicyLink from "../helpers/cookiePolicyLink";

import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin.esm";

const Footer = () => {
  return (
    <footer className="bg-black z-30 relative">
      <div className="container w-full lg:w-1000 mx-auto pt-10 pb-10 px-4">
        <div className=" flex flex-col md:flex-row">
          <div className="pt-2">
            <div className="h-1 sm:h-[12px] mx-auto">
              <StaticImage
                src={"../../images/reel/reel_logo_white.png"}
                placeholder="blurred"
                height={12}
                layout="constrained"
                loading="lazy"
                alt="reel logo"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-3 mt-10 md:mt-0 w-full">
            <div className="md:ml-10 lg:ml-20">
              <p className="text-white font-regular md:mb-6">
                <FormattedMessage id="footer_company_heading" />
              </p>
              <ul className="text-gray-300 font-light list-reset mb-6">
                <li className="mt-4 mr-2 md:block md:mr-0">Reel ApS</li>
                <li className="mt-4 mr-2 md:block md:mr-0">CVR: 41202807</li>
                <li className="mt-4 mr-2 md:block md:mr-0">
                  <CookiePolicyLink></CookiePolicyLink>
                </li>
                <li className="mt-4 mr-2 md:block md:mr-0">
                  <PrivacyPolicyLink></PrivacyPolicyLink>
                </li>
              </ul>
            </div>
            <div className="md:ml-10 lg:ml-20">
              <p className="text-white font-regular md:mb-6">
                <FormattedMessage id="footer_office_heading" />
              </p>
              <ul className="text-gray-300 font-light list-reset mb-6">
                <li className="mt-4 mr-2 md:block md:mr-0">
                  <FormattedMessage id="footer_office_address" />
                </li>
                <li className="mt-4 mr-2 md:block md:mr-0">
                  <FormattedMessage id="footer_office_city" />
                </li>
                <li className="mt-4 mr-2 md:block md:mr-0">
                  <FormattedMessage id="footer_office_country" />
                </li>
              </ul>
            </div>
            <div className="md:ml-10 lg:ml-20">
              <p className="text-white font-regular md:mb-6">
                <FormattedMessage id="footer_contact_heading" />
              </p>
              <ul className="text-gray-300 font-light list-reset mb-6">
                <li className="mt-4 mr-2 md:block md:mr-0">
                  {" "}
                  <a href="mailto:contact@reel.energy">contact@reel.energy</a>
                </li>
                <li className="mt-4 mr-2 md:block md:mr-0">+45 51 52 83 19</li>
                <li className="mt-4 mr-2 md:block md:mr-0">
                  <a
                    href="https://www.linkedin.com/company/reelenergy/"
                    className="flex"
                  >
                    LinkedIn <FaLinkedin className="text-xl ml-1" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
