import React, { useState } from "react";

import ReactModal from 'react-modal';

import OfferForm from "../offer/form";
import { FormattedMessage } from "gatsby-plugin-react-intl";

import LocalizedLink from "../helpers/localizedLink";

const Cta = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleOpenModal() {
    setIsModalOpen(true)
  }

  function handleCloseModal() {
    setIsModalOpen(false)
  }

  return (
    <div className="bg-black">
      <div className="w-full py-4 md:py-12">
        <div className="w-full lg:w-1000 mx-auto px-4 flex flex-col">
          <div className="flex flex-col lg:flex-row justify-between bg-blueish-400 rounded-md py-8 px-10 items-center ">
            <div className="font-medium text-white text-base sm:text-base text-center md:text-lg lg:text-xl">
              <FormattedMessage id="ready_to_switch" />
            </div>
            <button onClick={handleOpenModal} className="btn-white-solid w-40 sm:w-40 md:w-60 flex flex-row items-center justify-center py-3 mt-6 lg:mt-0 group text-sm sm:text-base font-medium">
              <FormattedMessage id="get_offer" />
            </button>
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={isModalOpen}
        contentLabel="Form modal"
        onRequestClose={handleCloseModal}
        className="modal z-50"
        overlayClassName="Overlay"
      >
        <div className="-mr-2 lg:-mr-0.5">
          <div className="relative z-50 w-full h-8 flex justify-end px-4">
            <button className="text-white text-2xl " onClick={handleCloseModal}>x</button>
          </div>
          <div className="w-full -mt-10 lg:-mt-20 z-40 relative">
            <OfferForm ></OfferForm>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default Cta;
