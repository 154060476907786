import React, { useState } from "react";
import {
  IntlContextConsumer,
  changeLocale,
  useIntl,
} from "gatsby-plugin-react-intl";
import { Popover } from "@headlessui/react";
import { FaAngleDown } from "@react-icons/all-files/fa/FaAngleDown";
import { FaCheck } from "@react-icons/all-files/fa/FaCheck";
import LocaleData from "../helpers/localeData";

const LanguageSwitcher = (props) => {
  const [showAboutPopover, setShowAboutPopover] = useState(false);

  const intl = useIntl();

  return (
    <div>
      <div
        href="#"
        className="hover:text-black px-3 py-2 text-sm font-medium cursor-pointer flex flex-row "
        aria-expanded="true"
        aria-haspopup="true"
        role="button"
        onMouseEnter={() => setShowAboutPopover(true)}
        onMouseLeave={() => setShowAboutPopover(false)}
        onClick={() => setShowAboutPopover(!showAboutPopover)}
        onKeyDown={() => setShowAboutPopover(!showAboutPopover)}
        tabIndex={0}
        style={{
          color: props.isLight ? "#fff" : "#000",
        }}
      >
        <Popover className="relative">
          <>
            <Popover.Button>
              <span className="flex flex-row">
                <div className="flex">
                  {LocaleData(intl.locale)["short"]}{" "}
                  <FaAngleDown className="mt-1 ml-1" />
                </div>
              </span>
            </Popover.Button>
            {showAboutPopover && (
              <div>
                <Popover.Panel
                  className="absolute top-6 -left-4 z-10 bg-gray-50 rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden px-6 py-4"
                  static
                >
                  <IntlContextConsumer>
                    {({ languages, language: currentLocale }) =>
                      languages.map((language, index) => (
                        <div key={language}>
                          <button
                            className="py-3 cursor-pointer w-full hover:text-blueish-400 flex"
                            style={{
                              color:
                                currentLocale === language
                                  ? "black"
                                  : "rgb(55, 65, 81)",
                            }}
                            key={language}
                            onKeyDown={() => changeLocale(language)}
                            onClick={() => changeLocale(language)}
                            tabIndex={0}
                          >
                            <span className="mr-1 mt-[1px]">
                              {LocaleData(language)["symbol"]}
                            </span>
                            {LocaleData(language)["long"]}
                            {currentLocale === language && (
                              <FaCheck className="ml-3 mt-[2px]" />
                            )}
                          </button>
                          {index < languages.length - 1 && (
                            <hr className="border-gray-100 border w-10 mx-auto" />
                          )}
                        </div>
                      ))
                    }
                  </IntlContextConsumer>
                </Popover.Panel>
              </div>
            )}
          </>
        </Popover>
      </div>
    </div>
  );
};

export default LanguageSwitcher;
