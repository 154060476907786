import React from "react";
import Footer from "./footer";
import Nav from "./nav";
import Cta from "./cta";

const Layout = ({ children, isLight }) => {
  return (
    <div className="w-screen relative overflow-hidden">
      <Nav isLight={isLight}></Nav>
      <main>{children}</main>
      <Cta></Cta>
      <Footer></Footer>
    </div>
  );
};

export default Layout;
